<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="6">
            <b-form-group>
              <label>Sub Dealer / Sales Executive</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Sub Dealer"
              >
                <v-select
                  :options="subdealerOptions"
                  label="name"
                  v-model="subdealer"
                  placeholder="None"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Year</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Year"
              >
                <v-select
                  :options="[
                    '2016-2017',
                    '2017-2018',
                    '2018-2019',
                    '2019-2020',
                    '2020-2021',
                    '2021-2022',
                    '2022-2023',
                    '2023-2024',
                    '2024-2025',
                    '2025-2026',
                    '2026-2027',
                    '2027-2028',
                    '2028-2029',
                    '2029-2030',
                    '2030-2031',
                    '2031-2032',
                    '2032-2033',
                    '2033-2034',
                    '2034-2035',
                    '2035-2036',
                    '2036-2037',
                    '2037-2038',
                    '2038-2039',
                    '2039-2040',
                    '2040-2041',
                    '2041-2042',
                    '2042-2043',
                    '2043-2044',
                    '2044-2045',
                    '2045-2046',
                    '2046-2047',
                    '2047-2048',
                    '2048-2049',
                    '2049-2050',
                    '2050-2051',
                  ]"
                  placeholder="None"
                  v-model="year"
                  @input="setYear($event)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <div style="width: 100%; overflow: auto">
              <table class="table table-bordered" style="width: 150cm">
                <thead class="text">
                  <tr>
                    <th style="width: 6cm">Employee / Month</th>
                    <th colspan="2">
                      <p style="text-align: center">Apr {{ first }}</p>
                    </th>
                    <th colspan="2">
                      <p style="text-align: center">May {{ first }}</p>
                    </th>
                    <th colspan="2">
                      <p style="text-align: center">Jun {{ first }}</p>
                    </th>
                    <th colspan="2">
                      <p style="text-align: center">Jul {{ first }}</p>
                    </th>
                    <th colspan="2">
                      <p style="text-align: center">Aug {{ first }}</p>
                    </th>
                    <th colspan="2">
                      <p style="text-align: center">Sep {{ first }}</p>
                    </th>
                    <th colspan="2">
                      <p style="text-align: center">Oct {{ first }}</p>
                    </th>
                    <th colspan="2">
                      <p style="text-align: center">Nov {{ first }}</p>
                    </th>
                    <th colspan="2">
                      <p style="text-align: center">Dec {{ first }}</p>
                    </th>
                    <th colspan="2">
                      <p style="text-align: center">Jan {{ second }}</p>
                    </th>
                    <th colspan="2">
                      <p style="text-align: center">Feb {{ second }}</p>
                    </th>
                    <th colspan="2">
                      <p style="text-align: center">Mar {{ second }}</p>
                    </th>
                    <th colspan="2">
                      <p style="text-align: center">Total</p>
                    </th>
                    <th>Action</th>
                  </tr>
                  <tr>
                    <th></th>
                    <th style="width: 6cm">
                      <p style="text-align: center">No of CP / Franchise</p>
                    </th>
                    <th style="width: 6cm">
                      <p style="text-align: center">Amount</p>
                    </th>
                    <th style="width: 6cm">
                      <p style="text-align: center">No of CP / Franchise</p>
                    </th>
                    <th style="width: 6cm">
                      <p style="text-align: center">Amount</p>
                    </th>
                    <th style="width: 6cm">
                      <p style="text-align: center">No of CP / Franchise</p>
                    </th>
                    <th style="width: 6cm">
                      <p style="text-align: center">Amount</p>
                    </th>
                    <th style="width: 6cm">
                      <p style="text-align: center">No of CP / Franchise</p>
                    </th>
                    <th style="width: 6cm">
                      <p style="text-align: center">Amount</p>
                    </th>
                    <th style="width: 6cm">
                      <p style="text-align: center">No of CP / Franchise</p>
                    </th>
                    <th style="width: 6cm">
                      <p style="text-align: center">Amount</p>
                    </th>
                    <th style="width: 6cm">
                      <p style="text-align: center">No of CP / Franchise</p>
                    </th>
                    <th style="width: 6cm">
                      <p style="text-align: center">Amount</p>
                    </th>
                    <th style="width: 6cm">
                      <p style="text-align: center">No of CP / Franchise</p>
                    </th>
                    <th style="width: 6cm">
                      <p style="text-align: center">Amount</p>
                    </th>
                    <th style="width: 6cm">
                      <p style="text-align: center">No of CP / Franchise</p>
                    </th>
                    <th style="width: 6cm">
                      <p style="text-align: center">Amount</p>
                    </th>
                    <th style="width: 6cm">
                      <p style="text-align: center">No of CP / Franchise</p>
                    </th>
                    <th style="width: 6cm">
                      <p style="text-align: center">Amount</p>
                    </th>
                    <th style="width: 6cm">
                      <p style="text-align: center">No of CP / Franchise</p>
                    </th>
                    <th style="width: 6cm">
                      <p style="text-align: center">Amount</p>
                    </th>
                    <th style="width: 6cm">
                      <p style="text-align: center">No of CP / Franchise</p>
                    </th>
                    <th style="width: 6cm">
                      <p style="text-align: center">Amount</p>
                    </th>
                    <th style="width: 6cm">
                      <p style="text-align: center">No of CP / Franchise</p>
                    </th>
                    <th style="width: 6cm">
                      <p style="text-align: center">Amount</p>
                    </th>
                    <th style="width: 6cm">
                      <p style="text-align: center">No of CP / Franchise</p>
                    </th>
                    <th style="width: 6cm">
                      <p style="text-align: center">Amount</p>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in multiple" :key="index">
                    <td>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Employee"
                      >
                        <v-select
                          v-model="item.employee_id"
                          placeholder="None"
                          :options="subdealerOptions"
                          label="name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.cfapril"
                        type="number"
                        @input="cftotalclick(index)"
                        placeholder="Enter No"
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.amountapril"
                        @input="amounttotalclick(index)"
                        type="number"
                        placeholder="Enter Amount"
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.cfmay"
                        type="number"
                        @input="cftotalclick(index)"
                        placeholder="Enter No"
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.amountmay"
                        @input="amounttotalclick(index)"
                        type="number"
                        placeholder="Enter Amount"
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.cfjune"
                        type="number"
                        @input="cftotalclick(index)"
                        placeholder="Enter No"
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.amountjune"
                        @input="amounttotalclick(index)"
                        type="number"
                        placeholder="Enter Amount"
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.cfjuly"
                        type="number"
                        @input="cftotalclick(index)"
                        placeholder="Enter No"
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.amountjuly"
                        @input="amounttotalclick(index)"
                        type="number"
                        placeholder="Enter Amount"
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.cfaugust"
                        type="number"
                        @input="cftotalclick(index)"
                        placeholder="Enter No"
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.amountaugust"
                        @input="amounttotalclick(index)"
                        type="number"
                        placeholder="Enter Amount"
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.cfseptember"
                        type="number"
                        @input="cftotalclick(index)"
                        placeholder="Enter No"
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.amountseptember"
                        @input="amounttotalclick(index)"
                        type="number"
                        placeholder="Enter Amount"
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.cfoctomber"
                        type="number"
                        @input="cftotalclick(index)"
                        placeholder="Enter No"
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.amountoctomber"
                        @input="amounttotalclick(index)"
                        type="number"
                        placeholder="Enter Amount"
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.cfnovenber"
                        type="number"
                        @input="cftotalclick(index)"
                        placeholder="Enter No"
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.amountnovenber"
                        @input="amounttotalclick(index)"
                        type="number"
                        placeholder="Enter Amount"
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.cfdecember"
                        type="number"
                        @input="cftotalclick(index)"
                        placeholder="Enter No"
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.amountdecember"
                        @input="amounttotalclick(index)"
                        type="number"
                        placeholder="Enter Amount"
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.cfjanuary"
                        type="number"
                        @input="cftotalclick(index)"
                        placeholder="Enter No"
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.amountjanuary"
                        @input="amounttotalclick(index)"
                        type="number"
                        placeholder="Enter Amount"
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.cffebaury"
                        type="number"
                        @input="cftotalclick(index)"
                        placeholder="Enter No"
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.amountfebaury"
                        @input="amounttotalclick(index)"
                        type="number"
                        placeholder="Enter Amount"
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.cfmarch"
                        type="number"
                        @input="cftotalclick(index)"
                        placeholder="Enter No"
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.amountmarch"
                        @input="amounttotalclick(index)"
                        type="number"
                        placeholder="Enter Amount"
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.totalcf"
                        disabled
                        type="number"
                        placeholder="Enter No"
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.totalamount"
                        disabled
                        type="number"
                        placeholder="Enter Amount"
                      />
                    </td>
                    <td>
                      <b-button
                        variant="outline-danger"
                        class="mt-0"
                        @click="removeRow(id)"
                      >
                        <feather-icon icon="TrashIcon" class="" />
                        <!-- <span>Delete</span> -->
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
          <b-button variant="primary" @click="addRow" class="mt-1 ml-1 mb-1">
            <feather-icon icon="PlusIcon" class="mr-25" />
            <span>Add More</span>
          </b-button>

          <!-- submit button -->
          <b-col cols="12" class="mt-1">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="submitForm"
              class="mr-4"
              :disabled="submitDisable"
            >
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- <template #code>
      {{ codeType }}
    </template> -->
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code";

import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import {
  BFormInput,
  BFormFile,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  min_value,
} from "@validations";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import axios from '@/components/axios';
import moment from "moment";

export default {
  components: {
    flatPickr,
    PinchScrollZoom,
    Datepicker,
    BFormFile,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
  },
  data() {
    return {
      subdealerOptions: [],
      subdealer: "",
      year: "",
      first: "",
      second: "",
      multiple: [
        {
          employee_id: "",
          cfapril: "",
          cfmay: "",
          cfjune: "",
          cfjuly: "",
          cfaugust: "",
          cfseptember: "",
          cfoctomber: "",
          cfnovenber: "",
          cfdecember: "",
          cfjanuary: "",
          cffebaury: "",
          cfmarch: "",
          amountapril: "",
          amountmay: "",
          amountjune: "",
          amountjuly: "",
          amountaugust: "",
          amountseptember: "",
          amountoctomber: "",
          amountnovenber: "",
          amountdecember: "",
          amountjanuary: "",
          amountfebaury: "",
          amountmarch: "",
          cftotal: "",
          totalamount: "",
          submitDisable: false,
        },
      ],
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      ifEdit: false,
    };
  },
  async mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Target") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push("/crm/target");
            }
          } else if (item.add !== 1) {
            this.$router.push("/crm/target");
          }
        }
      });
    }
    await this.getEmployee();
    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getEditValue();
  },
  methods: {
    addRow() {
      this.multiple.push({
        employee_id: "",
        cfapril: "",
        cfmay: "",
        cfjune: "",
        cfjuly: "",
        cfaugust: "",
        cfseptember: "",
        cfoctomber: "",
        cfnovenber: "",
        cfdecember: "",
        cfjanuary: "",
        cffebaury: "",
        cfmarch: "",
        amountapril: "",
        amountmay: "",
        amountjune: "",
        amountjuly: "",
        amountaugust: "",
        amountseptember: "",
        amountoctomber: "",
        amountnovenber: "",
        amountdecember: "",
        amountjanuary: "",
        amountfebaury: "",
        amountmarch: "",
        totalcf: "",
        totalamount: "",
      });
    },
    cftotalclick(index) {
      // if(this.multiple[index].cfapril == ''){
      //   this.multiple[index].cfapril
      // }
      this.multiple[index].cfapril =
        this.multiple[index].cfapril == "" ? 0 : this.multiple[index].cfapril;
      this.multiple[index].cfmay =
        this.multiple[index].cfmay == "" ? 0 : this.multiple[index].cfmay;
      this.multiple[index].cfjune =
        this.multiple[index].cfjune == "" ? 0 : this.multiple[index].cfjune;
      this.multiple[index].cfjuly =
        this.multiple[index].cfjuly == "" ? 0 : this.multiple[index].cfjuly;
      this.multiple[index].cfaugust =
        this.multiple[index].cfaugust == "" ? 0 : this.multiple[index].cfaugust;
      this.multiple[index].cfseptember =
        this.multiple[index].cfseptember == ""
          ? 0
          : this.multiple[index].cfseptember;
      this.multiple[index].cfoctomber =
        this.multiple[index].cfoctomber == ""
          ? 0
          : this.multiple[index].cfoctomber;
      this.multiple[index].cfnovenber =
        this.multiple[index].cfnovenber == ""
          ? 0
          : this.multiple[index].cfnovenber;
      this.multiple[index].cfdecember =
        this.multiple[index].cfdecember == ""
          ? 0
          : this.multiple[index].cfdecember;
      this.multiple[index].cfjanuary =
        this.multiple[index].cfjanuary == ""
          ? 0
          : this.multiple[index].cfjanuary;
      this.multiple[index].cffebaury =
        this.multiple[index].cffebaury == ""
          ? 0
          : this.multiple[index].cffebaury;
      this.multiple[index].cfmarch =
        this.multiple[index].cfmarch == "" ? 0 : this.multiple[index].cfmarch;
      this.multiple[index].totalcf =
        parseInt(this.multiple[index].cfapril) +
        parseInt(this.multiple[index].cfmay) +
        parseInt(this.multiple[index].cfjune) +
        parseInt(this.multiple[index].cfjuly) +
        parseInt(this.multiple[index].cfaugust) +
        parseInt(this.multiple[index].cfseptember) +
        parseInt(this.multiple[index].cfoctomber) +
        parseInt(this.multiple[index].cfnovenber) +
        parseInt(this.multiple[index].cfdecember) +
        parseInt(this.multiple[index].cfjanuary) +
        parseInt(this.multiple[index].cffebaury) +
        parseInt(this.multiple[index].cfmarch);
    },
    amounttotalclick(index) {
      this.multiple[index].amountapril =
        this.multiple[index].amountapril == ""
          ? 0
          : this.multiple[index].amountapril;
      this.multiple[index].amountmay =
        this.multiple[index].amountmay == ""
          ? 0
          : this.multiple[index].amountmay;
      this.multiple[index].amountjune =
        this.multiple[index].amountjune == ""
          ? 0
          : this.multiple[index].amountjune;
      this.multiple[index].amountjuly =
        this.multiple[index].amountjuly == ""
          ? 0
          : this.multiple[index].amountjuly;
      this.multiple[index].amountaugust =
        this.multiple[index].amountaugust == ""
          ? 0
          : this.multiple[index].amountaugust;
      this.multiple[index].amountseptember =
        this.multiple[index].amountseptember == ""
          ? 0
          : this.multiple[index].amountseptember;
      this.multiple[index].amountoctomber =
        this.multiple[index].amountoctomber == ""
          ? 0
          : this.multiple[index].amountoctomber;
      this.multiple[index].amountnovenber =
        this.multiple[index].amountnovenber == ""
          ? 0
          : this.multiple[index].amountnovenber;
      this.multiple[index].amountdecember =
        this.multiple[index].amountdecember == ""
          ? 0
          : this.multiple[index].amountdecember;
      this.multiple[index].amountjanuary =
        this.multiple[index].amountjanuary == ""
          ? 0
          : this.multiple[index].amountjanuary;
      this.multiple[index].amountfebaury =
        this.multiple[index].amountfebaury == ""
          ? 0
          : this.multiple[index].amountfebaury;
      this.multiple[index].amountmarch =
        this.multiple[index].amountmarch == ""
          ? 0
          : this.multiple[index].amountmarch;
      this.multiple[index].totalamount =
        parseInt(this.multiple[index].amountapril) +
        parseInt(this.multiple[index].amountmay) +
        parseInt(this.multiple[index].amountjune) +
        parseInt(this.multiple[index].amountjuly) +
        parseInt(this.multiple[index].amountaugust) +
        parseInt(this.multiple[index].amountseptember) +
        parseInt(this.multiple[index].amountoctomber) +
        parseInt(this.multiple[index].amountnovenber) +
        parseInt(this.multiple[index].amountdecember) +
        parseInt(this.multiple[index].amountjanuary) +
        parseInt(this.multiple[index].amountfebaury) +
        parseInt(this.multiple[index].amountmarch);
    },
    removeRow(index) {
      this.multiple.splice(index, 1);
    },
    async getEmployee(id) {
      await axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/team`,
      }).then((response) => {
        this.subdealerOptions = response.data.data.data;
        if (id !== undefined) {
          this.subdealerOptions.map((item) => {
            if (item.id == id) {
              this.subdealer = item;
            }
          });
        }
      });
    },
    setYear(e) {
      let arr = [];
      arr = e.split("-");
      this.first = arr[0];
      this.second = arr[1];
    },
    async getEditValue() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/gettargetById/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.getEditItems(json.data.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(data) {
      data.map((item) => {
        this.subdealer = this.getEmployee(item.subdealersales);
        this.year = item.year;
        this.noofcpf = item.noofcpf;
        this.multiple = item.targetItem;
      });
      this.setEmployee();
    },
    setEmployee() {
      this.multiple.map((item) => {
        this.subdealerOptions.map((inner) => {
          if (item.employee_id == inner.id) {
            item.employee_id = inner;
          }
        });
      });
    },
    submitForm(e) {
      this.$refs.simpleRules.validate().then(async (success) => {
        let data;
        if (success) {
          this.multiple.map((item) => {
            item.employee_id = item.employee_id ? item.employee_id.id : "";
          });
        }
        data = {
          active: 1,
          subdealersales: this.subdealer.id,
          year: this.year,
          // noofcpf: this.noofcpf,
          data: this.multiple,
        };
        // var ifEdit = this.$route.params.id ? true : false;

        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.submitDisable = true;

          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/target/${this.$route.params.id}`
              : `${baseApi}/target`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.submitDisable = false;

              this.$swal({
                title: "Submited",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                  ? `${json.data.success}`
                  : "Update SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              // console.log(json, "json====");
              this.$router.push("/crm/target");
            })
            .catch((error) => {
              this.submitDisable = false;

              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    onClickBack() {
      this.$router.push("/crm/target");
    },
  },
};
</script>
